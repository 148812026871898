exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3jttI{display:-ms-flexbox;display:flex;height:100vh;width:100%;scrollbar-gutter:stable}@media (min-width:1024px){._3jttI{-ms-flex-direction:row;flex-direction:row}}._3jttI svg path{vector-effect:non-scaling-stroke}._2E29O{-ms-flex:1 0;flex:1 0;height:100%;max-width:calc(100% - 35.4rem);padding:6rem}@media (min-width:1280px){._2E29O{padding:3.2rem 2.4rem 4.8rem}}", ""]);

// exports
exports.locals = {
	"root": "_3jttI",
	"content": "_2E29O"
};